import { Button } from "@/components/ui/button";
import IconButton from "@/components/ui/icon-button";
import { cn } from "@/lib/utils";
import {
  ArrowsIn,
  ArrowsOut,
  BookmarkSimple,
  CircleNotch,
  MagnifyingGlass,
} from "@phosphor-icons/react";
import Placeholder from "@tiptap/extension-placeholder";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import BooleanHighlight from "./BooleanHighlightExtension";

//#region BaseEditor

interface TiptapEditorProps {
  extensions?: NonNullable<Parameters<typeof useEditor>[0]>["extensions"];
  content?: string;
  editorProps?: NonNullable<Parameters<typeof useEditor>[0]>["editorProps"];
  className?: string;
  placeholder?: string;
}

interface InlineSearchEditorProps {
  handleSearch: (value: string) => void;
  handleExpand: () => void;
  content?: string;
}

export interface ExpandedSearchEditorProps extends InlineSearchEditorProps {
  isSearching?: boolean;
}

const BaseEditor = ({
  extensions = [],
  content = "Type here for boolean search",
  editorProps = {},
  className,
  placeholder = "Type Here for boolean search",
}: TiptapEditorProps) => {
  const editor = useEditor({
    extensions: [
      Placeholder.configure({
        placeholder,
        emptyEditorClass: cn(
          "cursor-text tracking-wide before:content-[attr(data-placeholder)]",
          "before:absolute before:opacity-50",
          "before-pointer-events-none",
        ),
      }),
      StarterKit,
      BooleanHighlight,
      ...extensions,
    ],
    content,
    editorProps: {
      attributes: {
        class: cn(
          "text-xs font-sans tracking-wide rounded-md border border-gray-200 drop-shadow-sm p-3",
          className,
        ),
      },
      handleKeyDown: (_view, event) => {
        if (event.key === "Enter") {
          event.preventDefault(); // Prevents new line creation
          return true;
        }
        return false;
      },
      ...editorProps,
    },
  });

  if (!editor) {
    return <p>Loading editor...</p>;
  }

  return <EditorContent editor={editor} />;
};

// #region InlineSearchEditor
const InlineSearchEditor = ({
  handleSearch,
  handleExpand,
  content,
}: InlineSearchEditorProps) => {
  const editor = useEditor({
    extensions: [
      Placeholder.configure({
        placeholder: "Search...",
        emptyEditorClass: cn(
          "cursor-text tracking-wide before:content-[attr(data-placeholder)]",
          "before:absolute before:opacity-50",
          "before-pointer-events-none",
        ),
      }),
      StarterKit.configure({
        heading: false,
        paragraph: {
          HTMLAttributes: {
            class: "inline-block m-0 p-0",
          },
        },
      }),
      BooleanHighlight,
    ],
    content,
    editorProps: {
      attributes: {
        class: cn(
          "text-xs font-sans focus:outline-none tracking-wide text-gray-900",
          "whitespace-nowrap overflow-auto [&::-webkit-scrollbar]:hidden scrollbar-none",
          "max-w-[calc(100%-3rem)] no-scrollbar",
        ),
      },
      handleKeyDown: (_view, event) => {
        if (event.key === "Enter") {
          // Prevents new line creation
          event.preventDefault();
          return true;
        }
        return false;
      },
    },
    onUpdate: ({ editor }) => {
      if (editor.isEmpty) {
        handleSearch("");
      }
    },
  });

  if (!editor) {
    return <p>Loading editor...</p>;
  }

  const onSearch = () => {
    if (editor) {
      const plainText = editor.getText();
      handleSearch(plainText);
    }
  };

  return (
    <div className="flex w-80 drop-shadow">
      <div className={"relative flex-1 focus:outline-1 focus:outline-blue-600"}>
        <div className="flex h-[30px] items-center border border-gray-200 border-r-0 rounded-l-md font-normal p-2 bg-white overflow-hidden">
          <div className={"flex-1 min-w-0"}>
            <EditorContent editor={editor} />
          </div>

          <div className="absolute right-0 flex items-center ml-2">
            {!editor.isEmpty && (
              <IconButton
                icon={<BookmarkSimple />}
                aria-label={"save key messages"}
                size={"sm"}
                variant={"icon-only"}
                className={"p-0"}
                tooltipLabel={"Save as key message"}
                crossOffset={-60}
              />
            )}

            <IconButton
              icon={<ArrowsOut />}
              aria-label="expand"
              size="sm"
              variant="icon-only"
              className="p-1 mr-1"
              tooltipLabel="Expand"
              crossOffset={30}
              onPressChange={handleExpand}
            />
          </div>
        </div>
      </div>
      <IconButton
        icon={<MagnifyingGlass />}
        aria-label="search key message"
        size="sm"
        className={cn(
          "rounded-none rounded-r-md h-[30px] w-8",
          editor.isEmpty && " border border-gray-200 bg-white",
        )}
        onPressChange={onSearch}
        variant={editor.isEmpty ? "icon-only" : "default"}
      />
    </div>
  );
};

// #region ExpandedSearchEditor

const ExpandedSearchEditor = ({
  handleSearch,
  handleExpand: handleCollapse,
  content,
  isSearching = false,
}: ExpandedSearchEditorProps) => {
  const editor = useEditor({
    extensions: [
      Placeholder.configure({
        placeholder: "Search...",
        emptyEditorClass: cn(
          "cursor-text tracking-wide before:content-[attr(data-placeholder)]",
          "before:absolute before:opacity-50",
          "before-pointer-events-none",
        ),
      }),
      StarterKit,
      BooleanHighlight,
    ],
    content,
    editorProps: {
      attributes: {
        class: cn(
          "text-xs font-sans tracking-wide rounded-md border border-gray-200 drop-shadow-sm p-3",
          "min-h-24",
        ),
      },
    },
    onUpdate: ({ editor }) => {
      if (editor.isEmpty) {
        handleSearch("");
      }
    },
  });

  const onSearch = () => {
    if (editor) {
      const plainText = editor.getText();
      handleSearch(plainText);
    }
  };

  if (!editor) {
    return <p>Loading editor...</p>;
  }

  //TODO: After searching for a term, variants should be interchanged between "Save as key message" and "Seacrh"

  return (
    <div className={"bg-white mb-3"}>
      <div className={"flex items-center py-2"}>
        <MagnifyingGlass size={16} className={"text-gray-600"} />
        <span className={"font-medium text-sm ml-1"}>Search</span>
      </div>
      <EditorContent editor={editor} />
      <div className={"tracking-wide flex items-center my-2 justify-end"}>
        <Button
          className={"h-[30px] text-xs"}
          variant={"ghost"}
          onPressChange={handleCollapse}
        >
          <ArrowsIn className={"text-gray-600 mr-1"} size={14} />
          Close
        </Button>
        <Button variant={"outline"} className={"h-[30px] text-xs mx-2"}>
          Save as key message
        </Button>
        <Button
          className={"h-[30px] text-xs flex items-center"}
          onPressChange={onSearch}
        >
          {isSearching && (
            <CircleNotch size={16} className={"mr-1 animate-spin"} />
          )}
          <span> {isSearching ? "Searching" : "Search"}</span>
        </Button>
      </div>
    </div>
  );
};

export { InlineSearchEditor, ExpandedSearchEditor };
export default BaseEditor;
