import { Mark } from "@tiptap/core";
import { Plugin } from "prosemirror-state";

const BooleanHighlight = Mark.create({
  name: "booleanHighlight",

  addAttributes() {
    return {
      keyword: {
        default: null,
      },
      quotedText: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [{ tag: "span.boolean-keyword" }, { tag: "span.quoted-text" }];
  },

  renderHTML({ mark }) {
    if (mark.attrs.keyword) {
      return [
        "span",
        {
          class: `boolean-keyword ${
            mark.attrs.keyword === "NOT"
              ? "text-red-600"
              : mark.attrs.keyword === "AND"
                ? "text-blue-600"
                : mark.attrs.keyword === "OR"
                  ? "text-green-600"
                  : "text-fuchsia-600"
          }`,
        },
      ];
    }
    if (mark.attrs.quotedText) {
      return [
        "span",
        {
          class: "text-fuchsia-600 quoted-text",
        },
        `"${mark.attrs.quotedText}"`,
      ];
    }
    return ["span", {}];
  },

  addProseMirrorPlugins() {
    let timeoutId: number | null = null;

    const processHighlights = (view: any) => {
      const { state, dispatch } = view;
      const { doc } = state;
      const regex = /\b(AND|OR|NOT)\b|("[^"]*")|('[^']*')/g;

      const tr = state.tr;
      tr.removeMark(0, doc.content.size, state.schema.marks.booleanHighlight);

      doc.descendants((node: any, pos: number) => {
        if (!node.isText || !node.text) return;

        let match: RegExpExecArray | null;
        while (true) {
          match = regex.exec(node.text);
          if (match === null) break;

          const start = pos + (match.index ?? 0);
          const end = start + (match[0]?.length ?? 0);

          const mark = state.schema.marks.booleanHighlight;
          if (!mark) return;

          if (match[1]) {
            // Boolean keywords (AND, OR, NOT)
            tr.addMark(start, end, mark.create({ keyword: match[1] }));
          } else if (match[2] || match[3]) {
            // Quoted text (both single and double quotes)
            const quotedContent = (match[2] || match[3])?.slice(1, -1) ?? "";
            tr.addMark(start, end, mark.create({ quotedText: quotedContent }));
          }
        }
      });

      if (tr.docChanged) {
        dispatch(tr);
      }
    };

    const debouncedProcess = (view: any) => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }

      timeoutId = window.setTimeout(() => {
        processHighlights(view);
        timeoutId = null;
      }, 500); // 500ms delay
    };

    return [
      new Plugin({
        props: {
          handleTextInput(view) {
            debouncedProcess(view);
            return false;
          },
          handleKeyDown(view, event) {
            if (
              event.key === "Backspace" ||
              event.key === "Delete" ||
              event.key === "Enter" ||
              event.key === '"' ||
              event.key === "'"
            ) {
              debouncedProcess(view);
            }
            return false;
          },
          handlePaste(view) {
            debouncedProcess(view);
            return false;
          },
        },
      }),
    ];
  },
});

export default BooleanHighlight;
