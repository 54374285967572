import {
  Switch as AriaSwitch,
  type SwitchProps as AriaSwitchProps,
  composeRenderProps,
} from "react-aria-components";

import { cn } from "@/lib/utils";

const Switch = ({ children, className, ...props }: AriaSwitchProps) => (
  <AriaSwitch
    className={composeRenderProps(className, (className) =>
      cn(
        "group inline-flex items-center gap-2 text-sm font-medium leading-none data-[disabled]:opacity-70",
        /* Font Styles */
        "font-normal text-sm leading-6 tracking-wide text-gray-800",
        className,
      ),
    )}
    {...props}
  >
    {composeRenderProps(children, (children) => (
      <>
        <div
          className={cn(
            "peer inline-flex h-6 w-11 shrink-0 items-center rounded-full border-2 border-transparent transition-colors",
            /* Cursor */
            "cursor-pointer group-data-[disabled]:cursor-not-allowed",
            /* Focus Visible */
            "group-data-[focus-visible]:outline-none group-data-[focus-visible]:ring-2 group-data-[focus-visible]:ring-slate-950 group-data-[focus-visible]:ring-offset-2 group-data-[focus-visible]:ring-offset-white dark:group-data-[focus-visible]:ring-slate-300 dark:group-data-[focus-visible]:ring-offset-slate-950",
            /* Disabled */
            "group-data-[disabled]:opacity-50",
            /* Selected */
            "bg-slate-200 group-data-[selected]:bg-violet-800 dark:bg-slate-800 dark:group-data-[selected]:bg-slate-50",
            /* Readonly */
            "group-data-[readonly]:cursor-default",
            /* Resets */
            "focus-visible:outline-none",
          )}
        >
          <div
            className={cn(
              "pointer-events-none block size-4 rounded-full bg-white shadow-lg ring-0 transition-transform dark:bg-slate-950",
              /* Selected */
              "translate-x-[2px] group-data-[selected]:translate-x-[22px]",
            )}
          />
        </div>
        {children}
      </>
    ))}
  </AriaSwitch>
);

export { Switch };
