import { cn } from "@/lib/utils";
import {
  Tooltip as AriaTooltip,
  type TooltipProps as AriaTooltipProps,
  TooltipTrigger as AriaTooltipTrigger,
  composeRenderProps,
} from "react-aria-components";

const TooltipTrigger = AriaTooltipTrigger;

interface CustomTooltipProps extends AriaTooltipProps {
  align?: "left" | "right" | "center";
}

const Tooltip = ({
  className,
  offset = 4,
  align = "center",
  ...props
}: CustomTooltipProps) => (
  <AriaTooltip
    offset={offset}
    className={composeRenderProps(className, (className) =>
      cn(
        "z-50 overflow-hidden rounded-md bg-gray-900 py-1.5 px-2 text-xs tracking-wide font-normal text-white shadow-sm animate-in fade-in-0",
        /* Entering */
        "data-[entering]:zoom-in-95",
        /* Exiting */
        "data-[exiting]:animate-out data-[exiting]:fade-out-0 data-[exiting]:zoom-out-95",
        /* Placement */
        "data-[placement=bottom]:slide-in-from-top-2 data-[placement=left]:slide-in-from-right-2 data-[placement=right]:slide-in-from-left-2 data-[placement=top]:slide-in-from-bottom-2",
        /* Text alignment */
        align === "left" && "text-left",
        align === "right" && "text-right",
        align === "center" && "text-center",
        className,
      ),
    )}
    {...props}
  />
);

export { Tooltip, TooltipTrigger };
