"use client";

import { useOnWindowResize } from "@/lib/hooks/useOnWindowResize";
import { cn } from "@/lib/utils";
import {
  type AvailableChartColorsKeys,
  getColorClassName,
} from "@/lib/utils/chartUtils.ts";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";

import { forwardRef, useCallback, useRef, useState } from "react";
import type { ChartLegendProps, LegendProps } from "./BarChart.types";
import ScrollButton from "./ScrollButton";

//#region LegendItem

interface LegendItemProps {
  name: string;
  color: AvailableChartColorsKeys;
  onClick?: (name: string, color: AvailableChartColorsKeys) => void;
  activeLegend?: string;
  mode?: "solid" | "gradient";
}

const LegendItem = <T extends string>({
  name,
  color,
  onClick,
  activeLegend,
  mode = "solid",
}: LegendItemProps & { name: T }) => {
  const hasOnValueChange = !!onClick;
  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: <todo: need to add key press actions>
    <li
      className={cn(
        "group inline-flex flex-nowrap items-center gap-1.5 whitespace-nowrap rounded px-2 py-1 transition",
        hasOnValueChange
          ? "cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800"
          : "cursor-default",
      )}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.(name, color);
      }}
    >
      <span
        className={cn(
          "size-2 shrink-0 rounded",
          getColorClassName(color, "bg", mode),
          activeLegend && activeLegend !== name ? "opacity-40" : "opacity-100",
        )}
        aria-hidden={true}
      />
      <p
        className={cn(
          "truncate whitespace-nowrap text-[8px] text-gray-700 dark:text-gray-300",
          hasOnValueChange &&
            "group-hover:text-gray-900 dark:group-hover:text-gray-50",
          activeLegend && activeLegend !== name ? "opacity-40" : "opacity-100",
        )}
      >
        {name}
      </p>
    </li>
  );
};

//#endregion

//#region Legend
type HasScrollProps = {
  left: boolean;
  right: boolean;
};

const Legend = forwardRef<HTMLOListElement, LegendProps>((props, ref) => {
  const {
    categories,
    colors = [],
    className,
    onClickLegendItem,
    activeLegend,
    enableLegendSlider = false,
    mode = "solid",
    rotateXAxisTick,
    ...other
  } = props;

  const scrollableRef = useRef<HTMLInputElement>(null);
  const [hasScroll, setHasScroll] = useState<HasScrollProps | null>(null);

  const checkScroll = useCallback(() => {
    const scrollable = scrollableRef?.current;
    if (!scrollable) return;

    const hasLeftScroll = scrollable.scrollLeft > 0;
    const hasRightScroll =
      scrollable.scrollWidth - scrollable.clientWidth > scrollable.scrollLeft;

    setHasScroll({ left: hasLeftScroll, right: hasRightScroll });
  }, [setHasScroll]);

  const scrollToTest = (direction: "left" | "right") => {
    const element = scrollableRef?.current;
    const width = element?.clientWidth ?? 0;

    if (element && enableLegendSlider) {
      element.scrollTo({
        left:
          direction === "left"
            ? element.scrollLeft - width
            : element.scrollLeft + width,
        behavior: "smooth",
      });
      setTimeout(() => {
        checkScroll();
      }, 400);
    }
  };

  return (
    <ol
      ref={ref}
      className={cn(
        "relative overflow-hidden left-8",
        className,
        rotateXAxisTick && "bottom-[-42px]",
      )}
      {...other}
    >
      <div
        ref={scrollableRef}
        className={cn(
          "flex h-full",
          enableLegendSlider
            ? hasScroll?.right || hasScroll?.left
              ? "snap-mandatory overflow-auto"
              : ""
            : "flex-nowrap",
        )}
      >
        {categories.map((category, index) => (
          <LegendItem
            // biome-ignore lint/suspicious/noArrayIndexKey: <no unique ids for categories>
            key={`legend-item-${index}`}
            name={category}
            color={colors[index] as AvailableChartColorsKeys}
            onClick={onClickLegendItem}
            activeLegend={activeLegend}
            mode={mode}
          />
        ))}
      </div>
      {enableLegendSlider && (hasScroll?.right || hasScroll?.left) ? (
        <div className="absolute bottom-0 right-0 top-0 flex items-center pr-1">
          <ScrollButton
            icon={CaretLeft}
            onClick={() => scrollToTest("left")}
            disabled={!hasScroll?.left}
          />
          <ScrollButton
            icon={CaretRight}
            onClick={() => scrollToTest("right")}
            disabled={!hasScroll?.right}
          />
        </div>
      ) : null}
    </ol>
  );
});

Legend.displayName = "Legend";

//# region ChartLegend

const ChartLegend = ({
  payload,
  categoryColors,
  setLegendHeight,
  activeLegend,
  onClick,
  enableLegendSlider = false,
  legendPosition = "right",
  yAxisWidth = 56,
  rotateXAxisTick,
}: ChartLegendProps) => {
  const legendRef = useRef<HTMLDivElement>(null);

  useOnWindowResize(() => {
    const calculateHeight = (height: number | undefined) =>
      height ? Number(height) + 15 : 60;
    setLegendHeight(calculateHeight(legendRef.current?.clientHeight));
  });

  const filteredPayload = payload.filter((item) => item.type !== "none");

  const paddingLeft =
    legendPosition === "left" && yAxisWidth ? yAxisWidth - 8 : 0;

  return (
    <div
      style={{ paddingLeft }}
      ref={legendRef}
      className={cn(
        "flex items-center",
        { "justify-center": legendPosition === "center" },
        { "justify-start": legendPosition === "left" },
        { "justify-end": legendPosition === "right" },
      )}
    >
      <Legend
        categories={filteredPayload.toReversed().map((entry) => entry.value)}
        colors={filteredPayload
          .toReversed()
          .map(
            (entry) =>
              categoryColors.get(entry.value) as AvailableChartColorsKeys,
          )}
        onClickLegendItem={onClick}
        activeLegend={activeLegend}
        enableLegendSlider={enableLegendSlider}
        rotateXAxisTick={rotateXAxisTick}
      />
    </div>
  );
};

ChartLegend.displayName = "ChartLegend";
LegendItem.displayName = "LegendItem";
Legend.displayName = "Legend";

export { Legend, LegendItem, ChartLegend };
