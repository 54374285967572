import { cn } from "@/lib/utils";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Dialog as AriaDialog,
  type DialogProps as AriaDialogProps,
  DialogTrigger as AriaDialogTrigger,
  Popover as AriaPopover,
  type PopoverProps as AriaPopoverProps,
  composeRenderProps,
} from "react-aria-components";

const PopoverTrigger = AriaDialogTrigger;

const Popover = ({
  className,
  offset = 4,
  placement = "bottom",
  ...props
}: AriaPopoverProps) => (
  <AriaPopover
    offset={offset}
    className={composeRenderProps(className, (className) =>
      cn(
        "border-gray-200 shadow",
        "z-50 rounded-md border bg-popover text-popover-foreground shadow-md outline-none",
        "data-[hovered]:bg-gray-100",
        /* Entering */
        "data-[entering]:animate-in data-[entering]:fade-in-0 data-[entering]:zoom-in-95",
        /* Exiting */
        "data-[exiting]:animate-out data-[exiting]:fade-out-0 data-[exiting]:zoom-out-95",
        /* Placement */
        "data-[placement=bottom]:slide-in-from-top-2 data-[placement=left]:slide-in-from-right-2 data-[placement=right]:slide-in-from-left-2 data-[placement=top]:slide-in-from-bottom-2",
        className,
      ),
    )}
    {...props}
  />
);

interface PopoverDialogProps extends Omit<AriaDialogProps, "children"> {
  variant?: "default" | "noPadding";
  footer?: React.ReactNode;
  children?: React.ReactNode;
}

function PopoverDialog({
  className,
  variant = "default",
  footer,
  children,
  ...props
}: PopoverDialogProps) {
  const contentRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<any>(null);
  const [showScrollbar, setShowScrollbar] = useState(false);
  const [maxContentHeight, setMaxContentHeight] = useState<
    number | undefined
  >();

  const checkOverflow = useCallback(() => {
    if (contentRef.current && dialogRef.current) {
      const viewportHeight = window.innerHeight;
      const dialogRect = dialogRef.current.getBoundingClientRect();
      const contentRect = contentRef.current.getBoundingClientRect();
      const footerHeight = footer ? 72 : 0;
      const popoverElement = dialogRef.current.closest("[data-placement]");
      const placement = popoverElement?.getAttribute("data-placement") as
        | "top"
        | "bottom"
        | undefined;

      let maxHeight: number;
      if (placement === "bottom") {
        const spaceBelow = viewportHeight - dialogRect.top;
        maxHeight = Math.min(
          spaceBelow - 20,
          contentRect.height + footerHeight,
        );
      } else if (placement === "top") {
        const spaceAbove = dialogRect.bottom;
        maxHeight = Math.min(
          spaceAbove - 20,
          contentRect.height + footerHeight,
        );
      } else {
        maxHeight = Math.min(
          viewportHeight * 0.85,
          contentRect.height + footerHeight,
        );
      }

      // Ensure we have enough space for the footer
      maxHeight = Math.max(maxHeight, footerHeight + 100);

      setMaxContentHeight(maxHeight);
      setShowScrollbar(contentRect.height > maxHeight - footerHeight);
    }
  }, [footer]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(checkOverflow);
    });

    const mutationObserver = new MutationObserver((mutations) => {
      const hasPlacementChange = mutations.some(
        (mutation) =>
          mutation.type === "attributes" &&
          mutation.attributeName === "data-placement",
      );
      if (hasPlacementChange) {
        requestAnimationFrame(checkOverflow);
      }
    });

    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
      const popoverElement = contentRef.current.closest("[data-placement]");
      if (popoverElement) {
        mutationObserver.observe(popoverElement, {
          attributes: true,
          attributeFilter: ["data-placement"],
        });
      }
    }

    window.addEventListener("resize", checkOverflow);

    // Initial check after animation
    const timeoutId = window.setTimeout(() => {
      requestAnimationFrame(() => {
        requestAnimationFrame(checkOverflow);
      });
    }, 100);

    return () => {
      window.clearTimeout(timeoutId);
      resizeObserver.disconnect();
      mutationObserver.disconnect();
      window.removeEventListener("resize", checkOverflow);
    };
  }, [checkOverflow]);

  return (
    <AriaDialog
      ref={dialogRef}
      className={cn(
        "bg-white rounded-md overflow-hidden flex flex-col focus:outline-none focus-visible:outline-none relative",
        variant === "default" && "p-5",
        className,
      )}
      style={{ maxHeight: maxContentHeight }}
      {...props}
    >
      <div
        ref={contentRef}
        className={cn(
          "overflow-y-auto flex-1 relative scrollbar-thin",
          !showScrollbar && "overflow-hidden",
        )}
      >
        <div className={cn("", footer && "pb-[72px]")}>{children}</div>
      </div>
      {footer && (
        <div className="border-t border-slate-200/40 p-3 absolute bottom-0 inset-x-0 bg-white shadow-sm rounded-b-md">
          {footer}
        </div>
      )}
    </AriaDialog>
  );
}

export { Popover, PopoverTrigger, PopoverDialog };
