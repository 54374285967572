import { cn } from "@/lib/utils";
import { X as Clear } from "@phosphor-icons/react";
import { type ForwardedRef, forwardRef, useRef } from "react";
import {
  Input as AriaInput,
  type InputProps as AriaInputProps,
  TextArea as AriaTextArea,
  type TextAreaProps as AriaTextAreaProps,
  TextField as AriaTextField,
  type TextFieldProps as AriaTextFieldProps,
  type ValidationResult as AriaValidationResult,
  type InputRenderProps,
  composeRenderProps,
} from "react-aria-components";
import { FieldError, Label } from "./field";

const TextField = AriaTextField;

const Input = forwardRef<HTMLInputElement, AriaInputProps>(
  ({ className, ...props }, forwardedRef) => {
    return (
      <AriaInput
        className={composeRenderProps(className, (className) =>
          cn(
            "flex h-10 w-full rounded-md border border-slate-200 bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400",
            /* Disabled */
            "data-[disabled]:opacity-50",
            /* Any focus (mouse or keyboard) */
            "data-[focused=true]:outline-none data-[focused=true]:ring-offset-0 data-[focused=true]:ring-0 data-[focused=true]:ring-blue-600 data-[focused=true]:border-blue-600 dark:data-[focused=true]:ring-slate-500",
            /* Keyboard focus specifically */
            "data-[focus-visible=true]:!ring-offset-2 data-[focus-visible=true]:!ring-2 data-[focus-visible=true]:!border-slate-200 data-[focus-visible=true]:ring-blue-750 dark:data-[focus-visible=true]:ring-slate-300",
            className,
          ),
        )}
        {...props}
        ref={forwardedRef} // Forward the ref
      />
    );
  },
);

const TextArea = forwardRef<HTMLTextAreaElement, AriaTextAreaProps>(
  ({ className, ...props }, forwardedRef) => {
    return (
      <AriaTextArea
        className={composeRenderProps(className, (className) =>
          cn(
            "flex w-full rounded-md border border-slate-200 bg-white px-3 py-2 text-sm ring-offset-white ring-offset-0 placeholder:text-slate-500 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400",
            /* Disabled */
            "data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50",
            /* Resets */
            "focus-visible:outline-none",
            /* Any focus (mouse or keyboard) */
            "data-[focused=true]:outline-none data-[focused=true]:ring-2 data-[focused=true]:ring-blue-600 dark:data-[focused=true]:ring-slate-500",
            /* Keyboard focus specifically */
            "data-[focus-visible=true]:ring-offset-2 data-[focus-visible=true]:ring-blue-750 dark:data-[focused=true]:ring-slate-300",
            /* Remove resize handle */
            "resize-none",
            className,
          ),
        )}
        {...props}
        ref={forwardedRef} // Forward the ref
      />
    );
  },
);

interface JollyTextFieldProps
  extends Omit<
    AriaTextFieldProps,
    "className" | "style" | "description" | "placeholder"
  > {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: AriaValidationResult) => string);
  textArea?: boolean;
  className?:
    | string
    | ((
        values: InputRenderProps & {
          defaultClassName: string | undefined;
        },
      ) => string);
  style?:
    | React.CSSProperties
    | ((
        values: InputRenderProps & {
          defaultStyle: React.CSSProperties;
        },
      ) => React.CSSProperties);
  rows?: number;
  placeholder?: string;
}

const JollyTextField = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  JollyTextFieldProps
>(
  (
    {
      label,
      description,
      errorMessage,
      textArea,
      className,
      style,
      rows,
      placeholder,
      ...props
    },
    forwardedRef: ForwardedRef<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    return (
      <AriaTextField className="group flex flex-col gap-2" {...props}>
        {label && <Label>{label}</Label>}
        {textArea ? (
          <TextArea
            className={className}
            style={style}
            placeholder={description || placeholder}
            rows={rows}
            ref={
              (forwardedRef as React.Ref<HTMLTextAreaElement>) || textAreaRef
            } // Pass ref to TextArea
          />
        ) : (
          <Input
            className={className}
            style={style}
            placeholder={description || placeholder}
            ref={(forwardedRef as React.Ref<HTMLInputElement>) || inputRef} // Pass ref to Input
          />
        )}
        <FieldError>{errorMessage}</FieldError>
      </AriaTextField>
    );
  },
);

interface ClearableTextFieldProps
  extends Omit<AriaTextFieldProps, "className"> {
  value: string;
  onClear: () => void;
  showClearButton?: boolean;
  className?: string;
  placeholder?: string;
  inputProps?: Partial<AriaInputProps>;
  label?: string;
  required?: boolean;
}

const ClearableTextField = forwardRef<
  HTMLInputElement,
  ClearableTextFieldProps
>(
  (
    {
      value,
      onClear,
      showClearButton = true,
      className,
      inputProps,
      label,
      required,
      ...props
    },
    ref,
  ) => {
    return (
      <TextField {...props}>
        {label && (
          <Label className={"text-xs"}>
            {label}
            {required && <span>*</span>}
          </Label>
        )}
        <div className="relative">
          <AriaInput
            ref={ref}
            value={value}
            className={composeRenderProps(className, (className) =>
              cn(
                "flex h-10 w-full rounded-md border border-slate-200 bg-white px-3 py-2 text-xs ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400",
                "data-[disabled]:opacity-50",
                "data-[focused=true]:outline-none data-[focused=true]:ring-offset-0 data-[focused=true]:ring-0 data-[focused=true]:ring-blue-600 data-[focused=true]:border-blue-600 dark:data-[focused=true]:ring-slate-500",
                "data-[focus-visible=true]:!ring-offset-2 data-[focus-visible=true]:!ring-2 data-[focus-visible=true]:!border-slate-200 data-[focus-visible=true]:ring-blue-750 dark:data-[focus-visible=true]:ring-slate-300",
                showClearButton && "pr-8",
                className,
              ),
            )}
            {...inputProps}
          />
          {/* TODO: icon button here */}
          {showClearButton && value && (
            <button
              type="button"
              className="absolute right-2 top-1/2 -translate-y-1/2 p-1 rounded-full bg-gray-200 hover:bg-gray-300 transition-colors"
              onClick={onClear}
            >
              <Clear className="h-2 w-2 text-white" />
            </button>
          )}
        </div>
      </TextField>
    );
  },
);

export {
  Input,
  TextField,
  JollyTextField,
  TextArea,
  type ClearableTextFieldProps,
  ClearableTextField,
};
export type { JollyTextFieldProps };
