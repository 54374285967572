import { Tooltip, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import type { IconProps } from "@phosphor-icons/react";
import { type VariantProps, cva } from "class-variance-authority";
import React, { cloneElement, isValidElement } from "react";
import {
  Button as AriaButton,
  type ButtonProps as AriaButtonProps,
} from "react-aria-components";

const iconButtonVariants = cva(
  "inline-flex items-center justify-center rounded-md transition-colors cursor-pointer",
  {
    variants: {
      variant: {
        default:
          "bg-violet-800 text-white hover:bg-violet-700 active:bg-violet-900",
        outline:
          "border border-gray-200 bg-background text-slate-800 hover:bg-slate-100 active:bg-slate-200 shadow-sm",
        ghost:
          "bg-transparent text-slate-800 hover:bg-slate-100 active:bg-slate-200",
        destructive: "bg-red-800 text-white hover:bg-red-700 active:bg-red-900",
        "icon-only": "text-gray-500 hover:text-gray-700 bg-transparent",
      },
      size: {
        sm: "w-6 h-6 p-1.5",
        md: "w-10 h-10 p-2",
        lg: "w-12 h-12 p-2.5",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "md",
    },
  },
);

const iconSizes = {
  sm: 16,
  md: 20,
  lg: 24,
} as const;

interface IconButtonProps
  extends Omit<AriaButtonProps, "children">,
    VariantProps<typeof iconButtonVariants> {
  /**
   * Phosphor icon element
   * @example icon={<Plus />}
   */
  icon: React.ReactElement<IconProps>;
  /**
   * Optional label for accessibility
   */
  "aria-label": string;
  /**
   * Optional class name for custom styling
   */
  className?: string;
  /**
   * Optional class name for icon styling
   */
  iconClassName?: string;
  /**
   * Optional label for tooltip
   */
  tooltipLabel?: string;
  /**
   * Optional position for tooltip
   */
  tooltipPosition?: "top" | "bottom" | "left" | "right";
  /**
   * Optional offset for tooltip
   */
  offset?: number;
  /**
   * Optional cross offset for tooltip
   */
  crossOffset?: number;
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      icon,
      size,
      variant,
      className,
      iconClassName,
      "aria-label": ariaLabel,
      tooltipLabel,
      tooltipPosition,
      ...props
    },
    ref,
  ) => {
    if (!isValidElement(icon)) {
      console.error(
        "IconButton requires a valid React element as the 'icon' prop.",
      );
      return null;
    }

    // Ensure size is not null before using it as an index
    const iconSize = size ? (iconSizes[size] ?? iconSizes.md) : iconSizes.md;

    const sizedIcon = cloneElement(icon, {
      size: iconSize,
      className: cn(iconClassName),
    });

    const button = (
      <AriaButton
        ref={ref}
        className={cn(iconButtonVariants({ variant, size }), className)}
        aria-label={ariaLabel}
        {...props}
      >
        {sizedIcon}
      </AriaButton>
    );

    if (tooltipLabel) {
      return (
        <TooltipTrigger>
          {button}
          <Tooltip
            placement={tooltipPosition}
            offset={props.offset}
            crossOffset={props.crossOffset}
          >
            {tooltipLabel}
          </Tooltip>
        </TooltipTrigger>
      );
    }

    return button;
  },
);

IconButton.displayName = "IconButton";

export { IconButton, iconButtonVariants };
export type { IconButtonProps };
export default IconButton;
